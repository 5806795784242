
const NewSection = () => {

  return (
    <div className="bg-black pt-10 pb-20 py-md-10">
      <div className="bg-black mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <section className="bg-position-top-center bg-repeat-0 pt-5 pb-5 py-md-10;">
          <div className="float-right">
            <img src="https://i.ibb.co/HVNfjm3/Remove-bg-ai-1712099740717.png" style={{ alignItems: "right", marginRight: 0 }} alt="" className=""></img>  
          </div>
          <div className="container pt-4 mb-3 mb-lg-0">
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-8 offset-lg-1">
                <div className="py-24 text-left">
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                    Enfriadores & Congeladores en Monterrey
                  </h1>
                  <p className="mt-8 max-w-3xl text-2xl text-white">
                  Equipos marca Imbera y Criotec a Precios de Remate
                  </p>
                  <div className="mt-10 flex-shrink-0">
                    <a href={`https://wa.me/528111028657?text=${encodeURIComponent('Quisiera recibir info de enfriadores')}`} rel="noopener noreferrer">
                      <button
                        className="text-2lg flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                      >
                        Quiero Cotizar
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NewSection;