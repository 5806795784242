// import ThinHeader from './components/ThinHeader';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Content from './components/Content';
import NewSection from './components/NewSection';

export default () => {
  return (
    <div className="bg-gray-50">
      <div>
        <header className="relative">
          <nav aria-label="Top">
            {/* <ThinHeader /> */}
            <Navigation />
          </nav>
        </header>
      </div>
      <div>
        <main>
          <NewSection />
          <Content />
        </main>
        <Footer />
      </div>
    </div>
  );
}
