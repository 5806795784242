const footerNavigation = {
  products: [
    { name: 'Criotec CFX-42', href: `https://wa.me/528111028657?text=${encodeURIComponent('Quisiera recibir info de Criotec CFX-42')}` },
    { name: 'Criotec CFX-37', href: `https://wa.me/528111028657?text=${encodeURIComponent('Quisiera recibir info de Criotec CFX-37')}` },
    { name: 'Criotec CFX-64', href: `https://wa.me/528111028657?text=${encodeURIComponent('Quisiera recibir info de Criotec CFX-64')}` },
    { name: 'Imbera CCV900', href: `https://wa.me/528111028657?text=${encodeURIComponent('Quisiera recibir info de Imbera CCV90')}` },
    { name: 'Imbera G342', href: `https://wa.me/528111028657?text=${encodeURIComponent('Quisiera recibir info de Imbera G342')}` },
  ],
}

export default footerNavigation;
