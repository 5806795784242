const products = [
  {
    id: 1,
    name: 'Criotec CFX-42',
    color: 'Doble',
    waText: 'Quisiera información del Criotec CFX-42 Doble',
    imageSrc: '/criotec42-doble.webp',
    imageAlt: 'Front fridge.',
    price: '$',
  },
  {
    id: 2,
    name: 'Criotec CFX-37',
    color: 'Doble',
    waText: 'Quisiera información del Criotec CFX-37 Doble',
    imageSrc: '/criotec37-doble.jpeg',
    imageAlt: 'Front fridge.',
    price: '$',
  },
  {
    id: 3,
    name: 'Criotec CFX-64',
    color: 'Triple',
    waText: 'Quisiera información del Criotec CFX-64 Triple',
    imageSrc: '/creiotec64-tripe.jpeg',
    imageAlt: 'Front fridge.',
    price: '$',
  },
  {
    id: 4,
    name: 'Imbera CCV900',
    color: 'Cervecero',
    waText: 'Quisiera información del Imbera CCV900 Cervecero',
    imageSrc: '/Imbera-G342.jpeg',
    imageAlt: 'Front fridge.',
    price: '$',
  },
  {
    id: 5,
    name: 'Imbera G342',
    color: 'Doble',
    waText: 'Quisiera información del Imbera G342 Doble',
    imageSrc: '/Imbera-cervecero.jpeg',
    imageAlt: 'Front fridge.',
    price: '$',
  },
  {
    id: 6,
    name: 'Criotec CTCC25',
    color: 'Congleador',
    waText: 'Quisiera información del Criotec CTCC25 Congleador',
    imageSrc: '/criotecCTCC25.jpeg',
    imageAlt: 'Front fridge.',
    price: '$',
  },
]

export default products;