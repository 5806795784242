const ListItem = ({ product }) => {
  return (
    <div key={product.id}>
      <div className="relative">
        <div className="relative h-72 w-full overflow-hidden rounded-lg">
          <img
            src={`${process.env.PUBLIC_URL}/img/${product.imageSrc}`}
            alt={product.imageAlt}
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div className="relative mt-4">
          <h3 className="text-sm font-medium text-gray-900">{product.name}</h3>
          <p className="mt-1 text-sm text-gray-500">{product.color}</p>
        </div>
        <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
          />
          <p className="relative text-lg font-semibold text-white">{product.price}</p>
        </div>
      </div>
      <div className="mt-6">
        <a
          href={`https://wa.me/528111028657?text=${encodeURIComponent(product.waText)}
          `}
          className="items-center justify-center rounded-md border border-transparent bg-green-600 px-8 py-2 text-sm font-medium text-white hover:bg-green-700"
        >
          Cotizar<span className="sr-only">, {product.name}</span>
        </a>
      </div>
    </div>
  );
};

export default ListItem;